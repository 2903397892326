export default class ABTestClientIDService {
  static COOKIE_KEY = 'mj_ab_uid'
  static COOKIE_MAX_AGE = 2 * 365 * 24 * 60 * 60 // 2 years
  static COOKIE_OPTIONS = `Max-Age=${ABTestClientIDService.COOKIE_MAX_AGE};HttpOnly;Path=/`

  static generateClientId () {
    return Math.round((Date.now() + Math.random()) * 1000)
  }

  static getClientId (store) {
    return store.ssrCookie[ABTestClientIDService.COOKIE_KEY]
  }

  static isOddPattern(store) {
    return ABTestClientIDService.getClientId(store) % 2 !== 0
  }
}

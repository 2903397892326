const CATEGORIES = {
  CL_COOPERATING: '参加者を見るページ',
  MJ_LINE_BUTTON: 'mj_LINE_button',
  CL_DATING_EVENT: 'デーティング',
  CL_MATCHING_EVENT: 'マッチング体験',
  MJ_SHARE_BUTTON: 'MJ共有ボタン',
  PURCHASE_SUCCESS: 'チケット詳細経由参加者を見るページ',
  MJ_PURCHASE_BUTTON: 'チケット一覧',
  MJ_EVENT_BOOKMARK: '気になるリスト',
  QUESTIONNAIRE_REMINDER_MODAL: 'アンケートモーダル',
  MJ_TOP_PAGE_SHORTCUT_LINK: 'MJTOPすぐ行けるイベント',
  MJ_SEARCH_BUTTON: 'ヘッダーこの条件で探すボタンabtest',
}

const ACTIONS = {
  CLICK: 'click',
  VIEW: 'view',
  PURCHASE: 'purchase'
}

export const CL_COOPERATING_CLICK = { category: CATEGORIES.CL_COOPERATING, action: ACTIONS.CLICK }
export const CL_COOPERATING_VIEW = { category: CATEGORIES.CL_COOPERATING, action: ACTIONS.VIEW }
export const MJ_LINE_BUTTON_CLICK = { category: CATEGORIES.MJ_LINE_BUTTON, action: ACTIONS.CLICK }
export const CL_DATING_EVENT_CLICK = { category: CATEGORIES.CL_DATING_EVENT, action: ACTIONS.CLICK }
export const CL_DATING_EVENT_PURCHASE = { category: CATEGORIES.CL_DATING_EVENT, action: ACTIONS.PURCHASE }
export const CONTACT_PURCHASE_SUCESS = '主催者よりイベントに関する確認事項がある場合は、machicon JAPANのメッセージ機能またはSMSでご連絡することがございます。'
export const CL_MATCHING_EVENT_CLICK = { category: CATEGORIES.CL_MATCHING_EVENT, action: ACTIONS.CLICK }
export const MJ_SHARE_BUTTON_CLICK = { category: CATEGORIES.MJ_SHARE_BUTTON, action: ACTIONS.CLICK }
export const MJ_PURCHASE_SUCCESS_CLICK = { category: CATEGORIES.PURCHASE_SUCCESS, action: ACTIONS.CLICK, label: '参加者を見るボタン' }
export const MJ_PURCHASE_BUTTON_CLICK = { category: CATEGORIES.MJ_PURCHASE_BUTTON, action: ACTIONS.CLICK }
export const MJ_EVENT_BOOKMARK_CLICK = { category: CATEGORIES.MJ_EVENT_BOOKMARK, action: ACTIONS.CLICK }
export const QUESTIONNAIRE_REMINDER_MODAL_CLICK = { category: CATEGORIES.QUESTIONNAIRE_REMINDER_MODAL, action: ACTIONS.CLICK }
export const MJ_TOP_PAGE_SHORTCUT_LINK_CLICK = { category: CATEGORIES.MJ_TOP_PAGE_SHORTCUT_LINK, action: ACTIONS.CLICK }
export const MJ_SEARCH_BUTTON_CLICK = { category: CATEGORIES.MJ_SEARCH_BUTTON, action: ACTIONS.CLICK }
export const BOOKMARK_EVENTS_NOTIFICATION_MODAL_VIEW = { action: ACTIONS.VIEW }
export const BOOKMARK_EVENTS_NOTIFICATION_MODAL_CLICK = { action: ACTIONS.CLICK }
export const TOP_PAGE_VIEW_AB_TEST = { category: 'アクセシビリティABテスト', action: ACTIONS.VIEW } // issue-6328
